<template>
  <div>
    <document-create :document-detail="documentDetail" :is-update="true" @reload-data="getDocumentDetail()" :title="$t('documents.page.edit')" :button-text="$t('folders.buttons.save')"></document-create>
  </div>
</template>

<script>
import DocumentCreate from "./DocumentCreate";
import {DocumentService} from "@/services";
import {ACTION_SET_ACTIVE_SIDEBAR} from "@/stores/common/actions";
// import i18n from "@/lang/i18n";

export default {
  name: "DocumentUpdate",
  components: {DocumentCreate},
  data() {
    return {
      documentDetail: {}
    }
  },
  computed: {
    docId() {
      return this.$route.params.id
    }
  },
  methods: {
    async getDocumentDetail() {
      await DocumentService.detail(this.docId)
          .then((res) => {
            if (res && res.data) {
              this.documentDetail = res.data
            }
          })
          .catch((err) => {
            console.log(err)
            // this.$toast.error(i18n.t('documents.messages.validation.no_document_detail'))
          })
    },
  },
  mounted() {
    this.getDocumentDetail()
  },
  created() {
    this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, 'documents')
  }
}
</script>

<style scoped>

</style>